import IDenniButt from "./1.png";
import IDenniDentist from "./2.png";
import IDenniPenis from "./3.png";
import IDenniBulge from "./4.png";
import IDenniBlowjob from "./5.png";
import IDenniTiedUp from "./6.png";
import IDenniGlare from "./denniglare.png";

export const DenniButt = IDenniButt;
export const DenniDentist = IDenniDentist;
export const DenniPenis = IDenniPenis;
export const DenniBulge = IDenniBulge;
export const DenniBlowjob = IDenniBlowjob;
export const DenniTiedUp = IDenniTiedUp;
export const DenniGlare = IDenniGlare;
export const Dennis = [DenniButt, DenniDentist, DenniPenis, DenniBulge, DenniBlowjob, DenniTiedUp, DenniGlare];
