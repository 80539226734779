import React from "react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import Router from "./router/main-router";

const theme = createTheme({
  palette: {
    primary: {
      main: "#f8c046",
    },
  },
});

const App = (): React.ReactElement => (
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  </>
);

export default App;
